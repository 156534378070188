
import React,{useEffect,useCallback,useState,useRef} from 'react'
// import {getContentPath} from  'system/AssetManager'
import gsap,{Power2} from 'gsap'
import {getContentPath, fetchCMSJSON} from 'system/AssetManager';
import SwiperCore, { Navigation, A11y, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import Globals  from 'system/Globals'
import {getModuleIcon,isVideo} from "../narrative/NarrativeMenu";
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import classNames from 'classnames';
import styles from './controller-narrative.module.css'
import nstyles from '../narrative/narrative.module.scss'
import NarrativeMenuItem  from 'narrative/NarrativeMenuItem'
import ControllerServerComs from 'system/ControllerServerComs'
// import { isCompositeComponent } from 'react-dom/test-utils'
import {ReactComponent as CaretRight} from '../assets/icons/caret_right.svg'
import {ReactComponent as CaretLeft} from '../assets/icons/caret_left.svg'
import {ReactComponent as PlayPausebtn} from '../assets/icons/playpausebtn.svg'
import {ReactComponent as PiP} from '../assets/icons/picture_in_picture.svg'
import {ReactComponent as PiPoff} from '../assets/icons/picture_in_picture_off.svg'
// import {ReactComponent as Pausebtn} from '../assets/icons/pausebtn.svg'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const ControllerPresentation = (props)=>{

    const [menuItems,setMenuItems] = useState([]);
    const [isOverSized, setIsOverSized] = useState(false);
    const [activeMenuIndex, setActiveMenuIndex] = useState(null);
    const [flatMenuItems, setFlatMenuItems] = useState();
    const [onLanding, setOnLanding] = useState(true);

    
    const refMenuContainer = useRef();
    const refHdFramePos = useRef(0); 

    useEffect(()=>{
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        console.log('controller presentation props', props)

    },[])

    useEffect(()=>{        
        let mitems=[]
        let flatMItems = [];
        let flatIndex = 0;

        if(props.model.menu_links){
            props.model.menu_links.forEach((val,index)=>{
                // let menuItem = {id:val.id, parent_id:val.group_id,label:val.label,index:index,ordering:val.ordering,page: val.page, thumbnail: val.thumbnail }
                // if(val.group){
                //     let grp = mitems.find(mi=>{return mi.label===val.group})
                //     if(!grp){
                //         grp={group_id:val.group_id,label:val.group, subs:[menuItem],ordering:val.group_ordering,index:index}
                //         mitems.push(grp)
                //     }
                //     else
                //         grp.subs.push(menuItem)
                
                // }else{
                //     mitems.push(menuItem)
                // }
                let menuItem = {id:val.id, parent_id:val.group_id, label:val.label, index:index,ordering:val.ordering, page: val.page, subs:[], thumbnail: val.thumbnail }

					if(val.group_id){
						let grp = mitems.find(mi=>{return mi.id===val.group_id})
						// console.log('grp', grp)
						if (grp){
							grp.subs.push(menuItem)
						
						}
							
						}else{
							mitems.push(menuItem)
						}
					
				
            })
            console.log("SET ",mitems)
            setMenuItems(mitems.sort((a,b) => a.ordering - b.ordering))

            mitems.forEach((obj0, index) => {
                // if (obj0.subs && obj0.subs.length > 0) {
                //     obj0.subs.forEach((sub, index2) => {
                //         let flatMenuItem = { parent_id:sub.parent_id,label:sub.label,index:index2,ordering:flatIndex,page: sub.page, thumbnail: sub.thumbnail }
                //         flatMItems.push(flatMenuItem)
                //         flatIndex+=1
                //     });
                // } else {
                //     let flatMenuItem = { parent_id:obj0.parent_id,label:obj0.label,index:index,ordering:flatIndex,page: obj0.page, thumbnail: obj0.thumbnail }
                //         flatMItems.push(flatMenuItem)
                //         flatIndex+=1
                // }

                // let pageBlank = null;
                let flatMenuItem = {id: obj0.id, parent_id:obj0.parent_id, label:obj0.label, index:index,ordering:flatIndex, page: obj0.page, thumbnail: obj0.thumbnail}
                flatMItems.push(flatMenuItem)

                if (obj0.subs && obj0.subs.length > 0) {
                    obj0.subs.forEach((sub, index2) => {
                        let flatMenuItem = {id: sub.id, parent_id:sub.parent_id,label:sub.label,index:index2,ordering:flatIndex,page: sub.page, thumbnail: sub.thumbnail }

                        // pageBlank = {'id': sub.page, 'ordering': flatIndex, 'components': [], 'controller_links': [], 'controller_states': [], 'formats': [], 'hd_size': [], 'menupage_id': null, 'nav': null, 'screenshot': null, 'scroll_offset': null, 'theme': 'default'}
                        // pageBlanks.push(pageBlank)

                        flatMItems.push(flatMenuItem)
                        flatIndex+=1
                        
                    });
                } 
                
             });
             setFlatMenuItems(flatMItems.sort((a,b) => a.ordering - b.ordering))
        }

    },[props.model.menu_links])

    useEffect(() => {
        console.log('props.activePage', props.activePage)
        if (props.activePage === "" && props.module && props.module.typeName==='controller-narrative') {
            
            // onClickLink(null, 1)
            
        }

        if (props && props.model) {
            console.log('controller narrative props', props)
            let elems1 = document.querySelectorAll('.stop1')
            elems1.forEach(element => {
              if (props.model.theme_css && props.model.theme_css['module-stop-0']) {
                element.setAttribute('stop-color', props.model.theme_css['module-stop-0'])
              }
            });
            let elems2 = document.querySelectorAll('.stop2')
            elems2.forEach(element => {
              if (props.model.theme_css && props.model.theme_css['module-stop-1']) {
                element.setAttribute('stop-color', props.model.theme_css['module-stop-1'])
              }
            });
        }
    }, [props])






    useEffect(() =>{
        if (props.activePage && props.activePage.hd_size) {
            if ((props.activePage.hd_size[0] > 1920)||(props.activePage.hd_size[1] < 1080)) {
                setIsOverSized(true);
            } else {
                setIsOverSized(false);
            }

            console.log('props.activePage', props.activePage)
        }
    }, [props.activePage])

    function sendZoneBack(command, operand){
        ControllerServerComs.instance().sendUDPFromControllerToServer('display-udp', props.user.display_ip +'?base=' + command + ',' + operand);
    }

    function sendAllZonesBack(){     
        sendZoneBack('ZONE.BACK', '=5;');
        setTimeout(() => {
            sendZoneBack('ZONE.BACK', '=6;');
        }, 100);
    }
    
    const onClickLink = (menuLinkIndex) =>{
        console.log("onClickLink", menuLinkIndex)
        
        if (props.activePage && props.user && props.user.display_ip && props.user.org && props.user.org.displaycommands) {
            sendAllZonesBack();
        }
   
        if (menuItems) {
            let index;

            if (menuLinkIndex) {
                index = menuLinkIndex
            } else {
                index = 0;
            }

            console.log('onClickLink', props.model.menu_links[index]);
            const page = props.model.menu_links[index].page;

            function onClickLinkStep(page, index) {

                console.log('onClickLinkStep', page, index)

                let newSerialPage = props.model.menu_links[index];
                let serial;
                if (newSerialPage) {
                    serial = props.model.menu_links[index].serial_string;
                }
                if (serial) {
                    ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link-with-lights', page + "?s=" + serial)
                    console.log('command', page + "?s=" + serial)
                } else {
                    ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link', page)
                }

                let pageIndex = props.pages.findIndex(obj=>{return obj.id === page});
                
                if (pageIndex > -1) {
                    props.setActivePage(props.pages[pageIndex]);
                    props.setActiveNarrativePageIndex(pageIndex);

                    console.log(pageIndex)
                
                }else{
                    props.setActivePage(null);
                }
                props.animNarrativeMenuPosForController()
                setActiveMenuIndex(index);
            }

            let pageIndex = props.pages.findIndex(obj=>{return obj.id === page});

            

            if (props.pages[pageIndex].loaded === true) {
                console.log('props.pages[pageIndex].loaded', props.pages[pageIndex].loaded)
                onClickLinkStep(page, index)

            } else {
            
                getPageAndTransition(pageIndex, page).then(() => {
                    
           
                    onClickLinkStep(page, pageIndex)
                
                });
            }
            
        

        }
    }
    

    const onExpandSubs = useCallback(evt=>{
        console.log('onExpandSubs', evt)
        let openEle = refMenuContainer.current.querySelector(`.${nstyles.menuLink}[open]:not([index="${evt}"])`)
        if(openEle){
            openEle.dispatchEvent(new CustomEvent('close'))
        }
    },[])


    function selectPage(pageId) {
        let pageIndex = props.pages.findIndex(obj=>{return obj.id === parseInt(pageId)});

        console.log('pageIndex', pageIndex)

        if (props.activePage && props.user && props.user.display_ip && props.user.org && props.user.org.displaycommands) {
            sendAllZonesBack();
        }

        function onSelectPage(pageIndex){
            console.log('handleCanvasClick')
            props.setActiveNarrativePageIndex(pageIndex)
            let newSerialPage = props.model.menu_links[pageIndex];
            let serial;
            if (newSerialPage) {
                serial = props.model.menu_links[pageIndex].serial_string;
            }
            if (serial) {
                ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link-with-lights', pageId + ",scroll?s=" + serial)
                console.log('command', pageId + ",scroll?s=" + serial)
            } else {
                ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link', pageId+ ",scroll")
            }
            
            // ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link', pageId +',scroll');           
            if (pageIndex > -1) {
                props.setActivePage(props.pages[pageIndex]);
                props.setActiveNarrativePageIndex(pageIndex);
            }
            // console.log('props.activePage', props.activePage, props.activeNarrativePageIndex, pageId, pageIndex, props.pages);

        }

        if (props.pages[pageIndex] && props.pages[pageIndex].loaded) {
            onSelectPage(pageIndex)
        } else {    
            getPageAndTransition(pageIndex, pageId).then(() => {

           
                onSelectPage(pageIndex)
            
            });
        }

       


        

        
    }

    const selectPrevPage = () => {
        let newNarrativePage = props.activeNarrativePageIndex - 1;
        if (newNarrativePage >= 0) {
            props.setActiveNarrativePageIndex(newNarrativePage)
            let pageId = props.pages[newNarrativePage].id
            selectPage(pageId)
        }
    }


    const selectNextPage = () => {
        let newNarrativePage = props.activeNarrativePageIndex + 1;
    
        if ((newNarrativePage <= (props.model.pages.length - 1))) {
            props.setActiveNarrativePageIndex(newNarrativePage)
            let pageId = props.pages[newNarrativePage].id

            selectPage(pageId)

            
        }
    }


    const goToNarrativeLink = (link, componentId) => {
        console.log('goToNarrativeLink', link, componentId)

        let urlparams= new URLSearchParams(link)
        let moduleId=urlparams.get("module")
        let pageId=urlparams.get("page")
        let slideId = urlparams.get("slide");
        let action=urlparams.get("action")
        let target=urlparams.get("target")

        
        // let moduleId = link.split("module=");
        // let narrativeId = link.split("narrative=");
        // let slideId = link.split("slide=");
        // let layerId = link.split("target=");

        // if (narrativeId[1]) {
        //     let narrativeTarget = narrativeId[1];
        //     let narrativeParams = narrativeTarget.split('?');
        //     let narrativeIdTarget = narrativeParams[0];

        //     narrativeId && console.log("goToNarrativeLink", narrativeIdTarget, props, '/narrative/' + narrativeTarget);
            
        //     props.setCurrentParams(null);
        //     props.setCurrentModule(null);
        //     props.setActivePage(null);
        //     props.setActiveNarrativePageIndex(0);
        //     props.setGoToNarrative(narrativeIdTarget);

        //     // history.push('/controllernarrative/'+narrativeIdTarget + '?nav=closed');
          
        //     setTimeout(()=>{
                
        //         ControllerServerComs.instance().sendUDPFromControllerToServer('navigate', '/narrative/' + narrativeTarget )
         
        //     },300)
        // } else 
        
        if (moduleId) {
            console.log('moduleId', moduleId)
            // let moduleParam = moduleId[1].split("&");
            let moduleIdTarget = moduleId;
            // if (moduleParam) {
            //     moduleIdTarget = moduleParam[0];
            // }
            // console.log('moduleId', moduleIdTarget, moduleParam)
            moduleId && console.log("goToNarrativeLink", moduleIdTarget, props);
            let moduleIndex = props.modules.findIndex(md=>{return md.id===moduleIdTarget})
            let m = props.modules.find(md=>{return md.id===moduleIdTarget})
            if(m.typeName!=="controller-narrative") props.setCurrentModule(m);
            if(m.typeName!=="controller-narrative") props.setAnimInCurrentModule(true);
            
            props.setCurrentParams(link + "&back=True");
            // props.setActivePage(null);
            // console.log('set current params, /narrative/' + props.model.id + link)
            
            setTimeout(()=>{
                ControllerServerComs.instance().sendUDPFromControllerToServer('navigate', '/narrative/' + props.model.id + '?' + link);
                ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-module-cta', moduleIndex-1);
                console.log('module link', '/narrative/' + props.model.id + '?' + link)
            },300)
            // console.log("goToNarrativeLink result", moduleIndex-1, m)
            
        } else if (slideId || pageId){ 
            // let slideParam = slideId[1].split("&");
            // let slide = slideParam[0];
            if (slideId) {
                selectPage(slideId);
                console.log('slide', slideId)
            } 
            if (pageId) {
                selectPage(pageId);
                console.log('pageId', pageId)
            }
            

       } else if (action === "layer" && componentId){
            ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-page-layer', componentId);
            console.log('narrative-page-layer link sent', link)
        } else {
            console.log('no link found')
        }
   
    }

    const showNarrativeTab = (evt) => {
        console.log('showNarrativeTab', evt)
        let state = props.activePage.controller_states[Number(evt.currentTarget.getAttribute('index'))];
        console.log(state)
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-page-state', state.id)
        let tabBtns = document.querySelectorAll('.tabBtnEle');

        for (let i = 0; i < tabBtns.length; i++) {
            tabBtns[i].removeAttribute('active');
          }

        evt.target.setAttribute('active', true)
        
    }

    function handleCanvasClick(e, pageId) {
       selectPage(pageId)
    }

    function handleScrollClick(dir) {
        console.log('dir', dir, props.activePage)
        if ((dir === 'right')&&(props.activePage)) {
            var hdFrame = document.getElementById("hdFrame_" + props.activePage.id);
            let img = document.getElementById("hdFrame_" + props.activePage.id + "_img");
            let img_w = img.offsetWidth;
            let hdFrame_w = hdFrame.offsetWidth;
            var leftPos = 0;
            if ((hdFrame_w + refHdFramePos.current) - img_w > 180) {
                leftPos = 180;
                console.log('leftPos', leftPos);
            } else {
                leftPos = (img_w - (hdFrame_w + refHdFramePos.current))
                console.log('refHdFramePos.current', leftPos, img_w, hdFrame_w, refHdFramePos.current);
            }
            gsap.to("#hdFrame_" + props.activePage.id, {left: leftPos, duration: 0.3})
            refHdFramePos.current = leftPos;
        }
        if ((dir === 'left')&&(props.activePage)) {
            // var hdFrame = document.getElementById("hdFrame_" + props.activePage.id);
            gsap.to("#hdFrame_" + props.activePage.id, {left: "0px", duration: 0.3})
        }
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-scroll', dir);
       
    }

    async function getPage(pageIndex, id){
        let pages = [ ...props.pages ]
        let page1 = null;

        
        console.log('getPage', pageIndex, id, flatMenuItems, pages)
    
        if (flatMenuItems && flatMenuItems.length > 0) {
            if (flatMenuItems[pageIndex]) {
                let path = Globals.instance().adminAPI + '/api/narrative/' + props.model.id + '/' + id + "?format=json"

                page1 = await fetchCMSJSON(path,null,null,null);
                pages[pageIndex] = page1
                pages[pageIndex]['loaded'] = true
            }
            // console.log('props.flatMenuItems', props.flatMenuItems)
            
                    //// platform filter
            pages.count > 0 && pages.forEach(p=>{
                if (p.components.length > 0) {
                    let filteredComponents = p.components.filter(c=>{
                        if(c.platform){
                            // console.log(c.platform, Globals.instance().ipad)
                            if (c.platform==="ipad" ) return  Globals.instance().ipad
                            else if (c.platform==="desktop" ) return  !Globals.instance().ipad
                            else return true
                        }
                        else return true
                    })
                    p.components=filteredComponents
                } 
                
    
            })
            console.log('newpages', pages)
            props.setPages(pages);
        }
        
        
    }
    
    function getPageAndTransition(pageIndex, id) {
		
		console.log('getPageAndTransition', pageIndex, id)
		// if(refInteraction.current)refInteraction.current.disconnect()
		// firstObservered = false;

		return getPage(pageIndex, parseInt(id))
	}

    function onVideoPlayClick(id) {
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-video', "play," + id);
    }


    function onVideoPauseClick(id) {
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-video', "pause," + id);
    }

    function onPip(command, operand){
        console.log('onPip', command, operand)
        ControllerServerComs.instance().sendUDPFromControllerToServer('display-udp', props.user.display_ip +'?base=' + command + ',' + operand);
        console.log('onPip sent', props.user.display_ip +'?base=' + command + ',' + operand)
    }

    return (
        <div className={`${nstyles.menuColPosContainer}`}>

        <div className={`${nstyles.menuColPos}`}><div className={`${nstyles.menuLinksContainer} hide-scroller`} >

   

           <div className={`${styles.linksContainer} hide-scroller`} ref={refMenuContainer} >
           
           {<span className={nstyles.switchMenu} onClick={() => props.setShowModules(showModules => !showModules)}><CaretLeft /><h4>Presentation</h4></span>}

                <div className={`${styles.controllerLinks} hide-scroller`} >

                    {
                        
                       menuItems && menuItems.map((val,index)=>{
                            return(
                               
                             <NarrativeMenuItem
                             model={val}
                             index={index}
                             key={`nar-menu-link-${index}`} 
                             onClickLink={ onClickLink } 
                             onExpand={onExpandSubs}
                             className={`${nstyles.menuLink} ${styles.controllerMenuLink}`}
                             theme={props.theme}
                            //  thumbnail={val.thumbnail}
                             activePage={props.activePage && props.activePage}
                             style={Globals.instance().getThemeStyle(['nav-menu-item'],props.theme)}
                             pageIndex={props.activeNarrativePageIndex && props.activeNarrativePageIndex}
                            //  pageIndex={props.pageIndex}
                             >
                     
                             </NarrativeMenuItem>
                            
                            )
                        })

                        
                    }

                </div>

            </div>
            </div></div>

            { props.pages && props.activePage &&
                    
                <section className={`${styles.moduleHolder}`}>


                        <div className={`${styles.grid33}`}>
                        {(isOverSized) ?(
                            <React.Fragment>
                            <div className={`${styles.splitBtn} ${styles.controllerBtn}`}>
                                <div className={`${styles.scrollLeft} ${styles.scrollArea}`} onClick={()=>handleScrollClick('left')}>
                                <CaretLeft />
                                </div>
                                <div className={`${styles.pageBtn}`} onClick={selectPrevPage}>
                                    <CaretLeft />
                                    <span className={`${styles.controllerScreenshot}`}>
                                    {props.pages && props.pages[props.activeNarrativePageIndex - 1] && 
                                        <img src={getContentPath(props.pages[props.activeNarrativePageIndex - 1].screenshot)} alt="screenshot" />
                                    }
                                    </span>
                                </div>
                            </div>
                            <div className={`${styles.splitBtn} ${styles.controllerBtn} ${styles.right}`}>
                              
                                 <div className={`${styles.pageBtn}`} onClick={selectNextPage}>
                                 <CaretRight />
                                 <span className={`${styles.controllerScreenshot}`}>
                            {props.pages && props.pages[props.activeNarrativePageIndex + 1] && 
                                <img src={getContentPath(props.pages[props.activeNarrativePageIndex + 1].screenshot)} alt="screenshot" />
                            }
                            </span>
                                </div>
                                <div className={`${styles.scrollLeft} ${styles.scrollArea}`} onClick={()=>handleScrollClick('right')}>
                                    <CaretRight />
                                </div>
                            </div>
                            

                          </React.Fragment>
                        ):(<React.Fragment>
                            <div className={`${styles.controllerBtn} ${styles.pageBtn} ${props.pages[props.activeNarrativePageIndex - 1]?"":styles.controllerBtnOff}`} onClick={selectPrevPage}>
                            
                                <span className={`${styles.controllerScreenshot}`}>
                                {props.pages && props.pages[props.activeNarrativePageIndex - 1] && props.pages[props.activeNarrativePageIndex - 1].screenshot &&  
                                    <img src={getContentPath(props.pages[props.activeNarrativePageIndex - 1].screenshot)} alt="screenshot" />
                                }
                                </span>
                            
                                <CaretLeft />

                            
                          </div>

                          <div className={`${styles.controllerBtn} ${styles.pageBtn} ${props.pages[props.activeNarrativePageIndex]?"":styles.controllerBtnOff}`}>
                            
                          <span className={`${styles.controllerScreenshot}`}>
                          {props.pages && props.pages[props.activeNarrativePageIndex] && props.pages[props.activeNarrativePageIndex].screenshot &&  
                              <img src={getContentPath(props.pages[props.activeNarrativePageIndex].screenshot)} alt="screenshot" />
                          }
                          {console.log(props.pages[props.activeNarrativePageIndex], props.activeNarrativePageIndex)}
                          </span>
                      
                        </div>


                          <div className={`${styles.controllerBtn} ${styles.pageBtn} ${props.pages[props.activeNarrativePageIndex + 1]?"":styles.controllerBtnOff}`} onClick={selectNextPage}>

                            <span className={`${styles.controllerScreenshot}`}>
                            {props.pages && props.pages[props.activeNarrativePageIndex + 1] && props.pages[props.activeNarrativePageIndex + 1].screenshot && 
                                <img src={getContentPath(props.pages[props.activeNarrativePageIndex + 1].screenshot)} alt="screenshot" />
                            }
                            </span>

                            <CaretRight />

                          </div>
                          </React.Fragment>)}
                      </div>

           
                    {props.activePage && 
                       <div className={`${styles.linkButtonContainerParent}`}>

                       {props.activePage.video_btns && props.activePage.video_btns.length > 0 && <span>
                        <h3>Videos</h3>
                       <div className={`${styles.linkButtonContainer}`}>   
                                             
                       {props.activePage.video_btns.map((obj,i) => (
                           <span key={"controller_link_" + i} className={styles.videoBtns}>
                                <p>{obj.heading.replace('video/','')}</p>
                                <span style={{display: "flex", gap: "26px", padding: "0 10px"}}>
                                <div className={`${styles.playBtn}`}  onClick={() => onVideoPlayClick(obj.id)}><PlayPausebtn /></div>
                                </span>
                           </span>
                       ))}
                       </div></span>}

                       
                         {props.activePage.controller_links && props.activePage.controller_links.length > 0 && <span>
                             <h3>Actions</h3>
                            <div className={`${styles.linkButtonContainer}`}>   
                                                  
                            {props.activePage.controller_links.map((obj,i) => (
                                <div className={`${styles.linkBtn}`} key={"controller_link_" + i} onClick={() => goToNarrativeLink(obj.link, obj.id)} data-id={obj.id}>{obj.heading}</div>
                               
                            ))}
                            </div></span>}
                            {props.activePage.controller_states && props.activePage.controller_states.length > 0 && 
                            <div className={`${styles.stateButtonContainer}`}>
                                <h3>Tabs</h3>
                                <div className={`${styles.stateButtons}`}>
                                {props.activePage.controller_states && props.activePage.controller_states.map((obj,i) => (
                                    <div className={`${styles.stateBtn} tabBtnEle`} key={"controller_link_" + i} onClick={showNarrativeTab} index={i} active={(i === 0)?"true":"false"} >{obj.state_heading}</div>
                                ))}
                                </div>
                            </div>}
                            <div className={`${styles.notesContainer}`}>   
                                <h3>Notes</h3>                 
                               <div className={`${styles.notes}`}>
                                    {props.activePage.notes && <span dangerouslySetInnerHTML={{__html: props.activePage.notes}} />}
                                </div>

                            </div>

                         

                        </div>}
                                
                        {props.activePage && props.user && props.user.display_ip && props.user.org && props.user.org.displaycommands && <span className={styles.pipIcons}>{props.user.org.displaycommands.filter(a => a.pip_loc === props.activePage.pip_loc).map((obj , i) => (<span className={styles.pipIconSet}><span onClick={() => onPip(obj.command, obj.operand)}className={classNames(
                            "round-btn",
                            styles.pipIcon
                         )}><PiP /></span><span onClick={() => onPip(obj.command_off, obj.operand_off)}className={classNames(
                            "round-btn",
                            styles.pipIcon
                         )}><PiPoff /></span></span>))}</span>}

                </section>   

                }

    </div>
    )

}

export default ControllerPresentation