
import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
import {getContentPath} from  'system/AssetManager'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState,setFinalAnimIntersectState} from './CanvasAnimator'
import Globals from 'system/Globals'
// import {onErrorLoad} from 'system/AssetManager'
import gsap, {Power3} from 'gsap'

function PNGComponent(props){
    
    
    const [inline,setInline] = useState({})
    const [arInline,setArInline] = useState({})
    const [imgSrc,setImgSrc] = useState(null)
     
    let bLoaded=false
    const loadingAnim = useRef();
    const refEle = useRef()
    const refArEle = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    
    
    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation && refFormat.current.animation.typeName){
            
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
       

            if(props.renderOnly===true){
                setFinalAnimIntersectState(refEle.current,refFormat.current.animation)
            }
        }
        
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        // console.log(refAnimationModel.current)
        animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        // console.log('props.pageState', props.pageState)
        
        if (props.pageState === "animPage" && inline && refAnimationModel.current && refAnimationModel.current.animations) {
            console.log('props.pageState', props.pageState)

            animateIntersect(refEle.current,refAnimationModel.current)

        
        }
    }, [props, inline])

    const onIntersect =  useCallback((evt,threshold)=>{
        
        // console.log('PNG onINTERSECT', evt)
        // console.log('onIntersect', evt.detail.intersectionRatio, threshold)

        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=threshold)
         
                animateIntersect(refEle.current,refAnimationModel.current)
              

        }
        else if(evt.detail.intersectionRatio <=0.001)
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
    },[refEle])

    useEffect(()=>{

        loadingAnim.current = gsap.to(refArEle.current, {
            backgroundColor: 'red',
            // backgroundColor: props.theme.css['primary-background'],
            duration: 1,
            repeat: -1, 
            yoyo: true,
            ease: "power1.inOut"
        });
       
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
        
        // let style ={position:'absolute',transform: 'translateZ(0), perspective: 1000px',willChange:'transform'}
        let imgStyle ={position:'relative',transform: 'translateZ(0), perspective: 1000px', opacity: 0,  'width': '100%', 'height': '100%'};
        let arStyle = {position:'absolute',transform: 'translateZ(0), perspective: 1000px', opacity: 0};

        if (refFormat.current) {
            let resStyle;
            if(refFormat.current.style && !props.pageBkg){ 
                let left = refFormat.current.style.left;
                let top =  refFormat.current.style.top;
                let width = refFormat.current.style.width;
                let objectFit = refFormat.current.style.objectFit;
                let opacity = refFormat.current.style.opacity;
                // let width = parseInt(refFormat.current.style.width)/window.innerWidth*100 + "%";
                let height = refFormat.current.style.height;
                
                imgStyle = Object.assign(imgStyle,{'opacity': opacity})
                arStyle = Object.assign(arStyle,{'left': left, 'top': top, 'width': width, 'height': height, 'objectFit': objectFit, 'opacity': opacity})
                
                console.log('newtop', width, refFormat.current.style, refEle.current.parentElement.offsetHeight)
                // style = Object.assign(style,refFormat.current.style) 
            }

      
            let f = refFormat.current.file?refFormat.current.file:refFormat.current.image
            console.log("setting ",getContentPath(f))

            console.log("FORMAT", refFormat.current, props.model.formats, props.format, f)

            setImgSrc(getContentPath(f))
            

   
            //set up animation Type
            if(refFormat.current.animation){

                if(refFormat.current.animation.typeName==='parallax'){
                    props.setScrollCallback(onScroll,props.index)
                    imgStyle.willChange='transform'
                } 
                else if(refFormat.current.animation.typeName==='page'){
                    props.onIntersect(onIntersect, props.index)
                    
                    imgStyle.willChange='transform, scale, opacity'
                    // console.log('anim page', props.index)
                }
            }
           
        }
        // console.log('refFormat.current.animation', refFormat.current.animation)
        setInline(imgStyle)
        setArInline(arStyle);
  
        
        return ()=>{}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function onError(evt){
        console.error("PNG load error"+bLoaded, evt.target.src)
        // onErrorLoad(evt)
        console.log("src "+evt.target.src)
        if(!bLoaded && imgSrc!==""){
            Globals.instance().trackError('PNG Component '+imgSrc+' ',false)
            onLoad() //dont hold up the page 
        }
    }
    
    function onLoad(){
        console.log('onLoad')
        //down res the image
        if(!bLoaded){
            props.onLoad(props.index,refEle.current,props.model)
            bLoaded=true


            loadingAnim.current.pause();

            gsap.to(refArEle.current, {
                backgroundColor: 'transparent',
                duration: 0.2,
                ease: "power1.inOut"
            });

            let opacity = refFormat.current.style.opacity;
            gsap.to(refEle.current, {opacity: opacity, duration: 0.2, delay: 1});

            if(props.renderOnly===true){
                refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
                setFinalAnimIntersectState(refEle.current,refAnimationModel.current)
            }

         

        }
        
    }
    
    return ( 
        <span className="arBox" style={arInline} ref={refArEle} id={refFormat.current && "component_dim_file_arBox_" + refFormat.current.component} data-fileFormat={refFormat.current && refFormat.current.file_format}>
            <img src={imgSrc} id={refFormat.current && "component_dim_file_" + refFormat.current.component} alt="" onLoad={onLoad} style={inline} ref={refEle} data-opacity={refFormat.current && refFormat.current.style && refFormat.current.style.opacity} className={'force3d canvasImg' + ((props.pageBkg)?" bkg":"")} onError={onError}></img>
        </span>    
        
            
    )

}

const PNG = memo(PNGComponent, (prev,next)=>{  return prev.imgSrc === next.imgSr })
export  {PNG}
