
import React,{useState,useRef,memo,useEffect,useCallback} from 'react'
import history from 'system/navigation/MainRouterHistory'
// import {fetchCMSJSON,fetchCMSJSONs} from 'system/AssetManager'
import styles from './page.module.css'
import * as DynamicComponentMap from  './canvas/dynamic'
import Globals from '../system/Globals'


const Page = memo(function(props){

    let loadCount = 0
    const refPage= useRef()
    const [inline,setInline] = useState({})
    const [inlineContainer,setInlineContainer] = useState({})
    const [localPage, setLocalPage] = useState(props.model);
    const [animPage, setAnimPage] = useState(false);
    

    const [format,setFormat] = useState(null)
    const refScrollTable =useRef({})
    
    const [loaded,setLoaded] = useState(false)
    const refCallbacks=useRef(new Array(props.model.components_count))
    // const refCallbacks = useRef()
    const refInteractionListener = useRef()
    // const refElements=useRef([])
    const refParallaxElements = useRef([])
    const refThrehold = useRef(0.5)
    const refContainerScale = useRef(1)
    const [renderNumber, setRenderNumber] =useState(0)
    
    //initial pageState
    let ps;
    props.model.components.map((val,i) => { if(val.component==="statePNGButton" && ps===undefined) ps=val.id })
    const [pageState,setPageState] = useState(ps)

    function setScrollTable(cb,index){
        console.log('Page setScrollTable', cb, index)
        if(cb) refScrollTable.current[index]=cb
    }

    const handleResize = ()=>{
        

        // console.log('handleResize')
        let format = Globals.instance().getFormat(props.model.formats, null, window.innerWidth)
        let w= format['canvas_width']?parseFloat(format['canvas_width']):window.innerWidth
        let h= format['canvas_height']?parseFloat(format['canvas_height']):'1080'
        if(w==='100%')w=window.innerWidth
        let scale=window.innerHeight/h
        refContainerScale.current=scale
        setInline({ width: '100%' ,height:'100%' })
        let leftOffset=  format.fit==="crop"?(window.innerWidth-(w*scale))/2:0
        if(leftOffset>0)leftOffset=0    
        if( format.fit==="fill" || format['canvas_width'] === "100%" )
        {w=window.innerWidth/scale}
        scale = Math.round(scale*1000)/1000 //round up a hair 

        console.log('scale etc', scale, format.aspect_ratio, props.model.inset, props.model.inset && props.model.inset.aspect_ratio, props.model.inset && parseFloat(props.model.inset.aspect_ratio), window.innerWidth/window.innerHeight)

        // if (props.model.inset && (parseFloat(props.model.inset.aspect_ratio).toFixed(2) === parseFloat(window.innerWidth/window.innerHeight).toFixed(2))) {
        console.log('AR Test', props.model.inset && parseFloat(props.model.inset.aspect_ratio).toFixed(2), Globals.instance().aspect_ratio)
        let frameWidth = document.getElementById('main').offsetWidth;
        let frameHeight = document.getElementById('main').offsetHeight;
        console.log("use inset", props.model.inset && (parseFloat(props.model.inset.aspect_ratio).toFixed(2) === parseFloat(frameWidth/frameHeight).toFixed(2)), parseFloat(frameWidth/frameHeight).toFixed(2), frameWidth)
        if (props.model.inset && (parseFloat(props.model.inset.aspect_ratio).toFixed(2) === parseFloat(frameWidth/frameHeight).toFixed(2))) {
            let scale = props.model.inset.width_ratio;
            let left =  (props.model.inset.inset_x * frameWidth) + "px";
            let top = (props.model.inset.inset_y * frameHeight) + "px";
            console.log('inset', scale, left, top)

            setInlineContainer({transform:'scale('+scale+')', transformOrigin:'top left', 'position': 'absolute', 'left': left, 'top': top})   
        }

        setRenderNumber(renderNumber+1)
        refThrehold.current = window.innerHeight/2
        if(refThrehold.current>0.5)refThrehold.current=0.5

    }

    const handleScroll = useCallback((scrollTop,screenWidth)=>{
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(refScrollTable.current)) {
           value.call(this,scrollTop,refContainerScale.current,screenWidth)
        }
        
    },[refScrollTable, refContainerScale])
    
    useEffect(()=>{
        let elePageRef=refPage.current
        //get format

        console.log('Globals.instance().getFormat(props.model.formats)', Globals.instance().getFormat(props.model.formats))
        setFormat(Globals.instance().getFormat(props.model.formats))
        
        handleResize()
        if(props.model.components.length===0){
            // console.warn("page with no components added")
            onLoadComplete()
        }

        let onServerEvent=(evt)=>{
            let detail = evt.detail
            if(!detail)return
            if(detail.event==='narrative-page-state'){
                console.log('PAGE STATE '+detail.command)
                let state =props.model.controller_states.find(m=>{return m.id===Number(detail.command)})
                console.log(state)
                if(state)
                doChangeState(Number(detail.command))
            } 
           
        }

        // console.log('props', props.model)

            //listen to server event
            if((Globals.instance().serverApp || (props.socketPrefs && props.socketPrefs.mode === "isReceive")) && props.model.controller_states && props.model.controller_states.length){
            window.removeEventListener('server-event',onServerEvent)
            window.addEventListener('server-event',onServerEvent)
        }

        return ()=>{
            window.removeEventListener('resize',handleResize)
            window.removeEventListener('server-event',onServerEvent)
            refCallbacks.current=undefined
            elePageRef.removeEventListener('intersection',refInteractionListener.current)
            setAnimPage(false)
        }      
           
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])



    const doChangeState =  useCallback((s)=>{ 
        console.log('set pagestate',s)
        setPageState(s)},[setPageState])



    function onLoadComplete(){

        console.log('onLoadComplete', props.model.id, props.index);
        
     
            // refInteractionListener.current = (evt)=>{
            //     // console.log("PAGE: ON INTERSECT "+ props.model.id +' '+ evt.detail.intersectionRatio + ' ' + evt.detail.isIntersecting)
    
            //     if(evt.detail.isIntersecting && evt.detail.intersectionRatio>0){
            //         props.onIntersect(props.model.id)
            //         // console.log('current', localPage, props.model.id)
            //     }
            //     refCallbacks.current.forEach(f=>{ f(evt,refThrehold.current) })
    
                
            // }
    
            // refPage.current.addEventListener('intersection', refInteractionListener.current, {passive:true})

            props.onIntersect(props.model.id)
            
            props.setScrollCallback(handleScroll,props.index)
            props.onLoad(props.index, refPage.current, refParallaxElements.current)
       
    }

    function onLoadComponent(index,refer,model){
        
        if(model && model.animation && model.animation.type==="parallax"){
            
            refParallaxElements.current.push({
                ele:refer,
                animations:model.animation.animations
            })
        }

        loadCount+=1
    
        if(loadCount >= props.model.components.length ){
            onLoadComplete()
            setLoaded(true)
            console.log('onLoadComponent', loadCount,  props.model.components)
        }
        // console.log('onLoadComplete', loadCount, props.model.components.length) 

    }

    function onIntersect(func,index){
        // console.log('onIntersect Page Function, Index', index)
        refCallbacks.current[index]=func
        
    }
    

    useEffect(() => {
        if (props.model.components && props.model.components.length > 0) {
            setLocalPage(props.model)

            console.log('props.model', props.model)
            if (props.model.components.length === props.model.components_count) {
                // onLoadComplete()
                // props.setScrollCallback(handleScroll, props.index)

                props.onIntersect(props.model.id)
                console.log('page loaded ' + props.model.id)
                
                props.onLoad(props.index, refPage.current, refParallaxElements.current)

                refPage.current.removeEventListener('intersection', refInteractionListener.current);
                
                refInteractionListener.current = (evt)=>{
                    // console.log("PAGE: ON INTERSECT 2 "+ props.model.id +' '+ evt.detail.intersectionRatio + ' ' + evt.detail.isIntersecting)
                    if(evt.detail.isIntersecting && evt.detail.intersectionRatio>0){
                        props.onIntersect(props.model.id)
                        console.log('evt.detail.isIntersecting', localPage, props.model.id, pageState)
                    }
                    refCallbacks.current && refCallbacks.current.forEach(f=>{ f(evt, refThrehold.current) })
                }
        
                refPage.current.addEventListener('intersection', refInteractionListener.current, {passive:true})
                props.setScrollCallback(handleScroll,props.index)  

            
            }
        }
    }, [props.model.components])





    function getComponent(model,i,isBkg){
       
        let Component = DynamicComponentMap[model.component]

       
        if(!Component){
            console.warn('NULL Element ignoring',model)
            loadCount+=1
            return (<div className="nullcomponent"></div>)
        }

        return Component? <Component 
        index={i} 
        pageBkg={isBkg}
        model={model} 
        key={props.index+'-'+props.id+'-'+i+'-'+props.model.components.length} 
        onLoad={onLoadComponent} 
        onIntersect={onIntersect} 
        setScrollCallback={setScrollTable}
        pageIndex={props.index} 
        pageId={props.model.id}
        theme={props.theme} 
        renderNumber={renderNumber}
        format={format}
        renderOnly={props.renderOnly}
        pageState={pageState}
        setPageState={doChangeState}
        interaction={props.interaction}
        nid={props.nid}
        flatMenuItems={props.flatMenuItems}
        onScrollToEvent={props.onScrollToEvent}
        setCurrentPage={props.setCurrentPage}
        socket={props.socket}
        socketPrefs={props.socketPrefs}
        onClickElem={props.onClickElem}
        // action={props.action}
        >
        </Component>:'none'
    }

    return ( 
        <div className={`${styles.container} Page force3d`} style={inline} ref={refPage} index={props.index} slideid={localPage && localPage.id} loaded={loaded?'true':'false'} location={localPage && props.index} scroll_offset={localPage && localPage.scroll_offset?parseFloat(localPage.scroll_offset)/100:0}>

        {console.log("localPage.components", localPage && localPage.components)}

            <span className={`${styles.backgroundArt} fullscreen force3d`}>
               {
               format && localPage.components.filter(o => o.is_background === true).map((val,i)=>{
                    return getComponent(val,i,true)
                })
            }
            </span>
            <div 
                className={`force3d ${styles.mediaContainer} ${format ? "arW_" + (localPage.default_aspect_ratio ? localPage.default_aspect_ratio : format.aspect_ratio.replace(".","")) : ""}`} 
                style={inlineContainer} 
                >


            {
                format && localPage.components.filter(o => o.is_background !== true).map((val,i)=>{
                     return getComponent(val,i,false)
                 })
             }
            
            </div>
        </div>
    )

},(prev,next)=>{
    return prev.pageState === next.pageState
    // return true 
    // JSON.stringify(prev.inline)=== JSON.stringify(next.inline)
    // return true
})


export default Page