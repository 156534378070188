import React,{forwardRef,useEffect,useState,useRef, Fragment} from 'react'
import LoaderWheel from 'system/components/LoaderWheel'
import {getContentPath} from 'system/AssetManager';
import * as THREE from "three";
import galleryStyles from './gallery.module.css';
import { render } from '@testing-library/react';
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import gsap,{Power2} from 'gsap'
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import { animateIntersect } from 'narrative/canvas/CanvasAnimator';
import {ReactComponent as VrCone} from 'assets/icons/vr_cone.svg';
import Globals from 'system/Globals';
import io from 'socket.io-client';
// TEST: Turn back on
// const socket = io(Globals.instance().socketURL);

function ThreeVR(props) {
    const touchInit = useRef(true);
    const refContainer = useRef(null);
    const cameraRef = useRef();
    const vrCone = useRef();
    const vrRef = useRef();
    const [vrLoaded,setVRLoaded] = useState(false);
    const [posX, setPosX] = useState(0);

    // if (props && props.user && props.user.org.broadcast) {
    //     socket = io(Globals.instance().socketURL);
    //     console.log('%csocket on', "color:green")
    // }


    useEffect(() => {
        if (props.socket && props.socketPrefs && props.socketPrefs.room) {
            console.log('threejs join', props.socketPrefs.room)
            // TEST: Turn back on
            // socket.emit("webgl-join", props.socketPrefs.room)
        }
    }, [props.socket, props.socketPrefs])


    useEffect(() => {
        if ((props.file !== null) && (vrLoaded === true)) {
            THREE.DefaultLoadingManager.onStart = function ( url, itemsLoaded, itemsTotal ) {
                console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
                setVRLoaded(false);

             
            };
            
            THREE.DefaultLoadingManager.onLoad = function ( ) {
                console.log( 'Loading Complete!');
                
                
            };
            
            THREE.DefaultLoadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
                console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
            };
            
            THREE.DefaultLoadingManager.onError = function ( url ) {
                console.log( 'There was an error loading ' + url );
            };
            console.log('props.rotation', props.rotation)
        }
    }, [props.file, vrLoaded])

    useEffect(() =>{
        // console.log('posX', posX)
        gsap.to(vrCone.current, {rotate: posX-180, duration: 0.3})
    }, [posX])

    useEffect(()=>{

        var requestAnimID;

        if (props.load === true) {
          
            console.log('vr props init', props);
            // setVRFilePath(props.file);
            var vrFilePath = getContentPath(props.file);
            
            console.log('filePath init', vrFilePath);
            
            var camera, scene, renderer;
            var isUserInteracting = false, onMouseDownMouseX = 0, onMouseDownMouseY = 0,
            lon = 0, onMouseDownLon = 0,
            lat = 0, onMouseDownLat = 0,
            phi = 0, theta = 0, onPointerDownPointerX = 0,  onPointerDownPointerY = 0, onPointerDownLon = props.startPos, onPointerDownLat = 0;
            

            console.log('props.startPos at init', props.startPos)

            init(vrFilePath);
            animate();
          
        
            function init(vrFilePath) {
    
    
                console.log('filePath anim', vrFilePath);
                var container, mesh;
                
                container = refContainer.current;
                // console.log('threeVR', container);
                camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 1100 );
                camera.target = new THREE.Vector3( 0, 0, 0 );
                scene = new THREE.Scene();        
                var geometry = new THREE.SphereGeometry( 500, 60, 40 );
                geometry.scale( - 1, 1, 1 );
    
                var material = new THREE.MeshBasicMaterial( {
                    map: new THREE.TextureLoader().load( vrFilePath )
                } );
    
                mesh = new THREE.Mesh( geometry, material );
                scene.add( mesh );
                
                renderer = new THREE.WebGLRenderer({});
                renderer.setPixelRatio( window.devicePixelRatio );
                renderer.setSize( window.innerWidth, window.innerHeight );
                container.appendChild( renderer.domElement );
    
                cameraRef.current = camera;

                document.addEventListener( 'mousedown', onDocumentMouseDown, false );
                document.addEventListener( 'mousemove', onDocumentMouseMove, false );
                document.addEventListener( 'mouseup', onDocumentMouseUp, false );
                document.addEventListener( 'wheel', onDocumentMouseWheel, false );
                window.addEventListener( 'resize', onWindowResize, false );
                document.addEventListener( 'touchstart', onDocumentTouchStart, false );
                document.addEventListener( 'touchmove', onDocumentTouchMove, false );
                document.addEventListener( 'touchend', onDocumentTouchEnd, false );

                if (props.vrRef) {
                    setTimeout(() => {
                        let offset;
                         offset = vrRef.current.getBoundingClientRect().y + vrRef.current.clientHeight/2 - vrCone.current.clientHeight + 13 + "px"
                         vrCone.current.style.top = offset
                        // console.log('offsetBottom', vrRef.current.offsetHeight, vrCone.current.offsetHeight);
                        setVRLoaded(true);
                    }, 300);

                   
                } else {
                    setVRLoaded(true);
                }
                

            }
    
              function onWindowResize() {
                camera.aspect = window.innerWidth / window.innerHeight;
                camera.updateProjectionMatrix();
                renderer.setSize( window.innerWidth, window.innerHeight );
            }
        
            function onDocumentMouseDown( event ) {
    
                event.preventDefault();
                isUserInteracting = true;

                if (touchInit.current && Globals.instance().serverApp ) {
                    onPointerDownPointerX = 0;
                    onPointerDownPointerY = 0;
                    onPointerDownLon = props.startPos;  
                    onPointerDownLat = lat;
                    touchInit.current = false;
                } else {
                    onPointerDownPointerX = event.clientX;
                    onPointerDownPointerY = event.clientY;
                    onPointerDownLon = lon;  
                    onPointerDownLat = lat;
                    
                }
          
                

                console.log('onDocumentMouseDown', event.clientX, event.clientY, lon, lat)
            }

            function onDocumentMouseMove( event ) {
                // console.log('onDocumentMouseMove', isUserInteracting);

              

                if ( isUserInteracting === true ) {

    
                    
                    if (props.rotation !== 'vertical') {
                       
                        let lonCalc = ( onPointerDownPointerX - event.clientX ) * 0.1 + onPointerDownLon;
                        // console.log('lon', lon, props.endPos, event.clientX, onPointerDownPointerX)
                        if (props.endPos) {
                            if (lonCalc > props.startPos && lonCalc < props.endPos) {
                                lon = ( onPointerDownPointerX - event.clientX ) * 0.1 + onPointerDownLon;
                                setPosX(lon);
                            }
                        } else {
                            lon = ( onPointerDownPointerX - event.clientX ) * 0.1 + onPointerDownLon;
                            setPosX(lon);

                            console.log('onMouseMove', onPointerDownPointerX, event.clientX, onPointerDownLon, lon)
                        } 
                            
                        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") { 
                            lat = 0
                            props.socket.emit('cast', [props.socketPrefs.room, 'vrfile-mousemove?pos=' + lon + "," + lat]);
                            console.log('cast onDocumentMouseMove', 'vrfile-mousemove?pos=' + lon + "," + lat)
                        }

                    }
                    if (props.rotation !== 'horizontal') {
                        lat = ( event.clientY - onPointerDownPointerY ) * 0.1 + onPointerDownLat;
                    }

               

                   
                    
                }
            }
        
            function onDocumentMouseUp( event ) {
                isUserInteracting = false;
            }
        
        
            function onDocumentTouchStart( event ) {
                // event.preventDefault();
                isUserInteracting = true;
                onPointerDownPointerX = event.touches[0].clientX;
                onPointerDownPointerY = event.touches[0].clientY;
                onPointerDownLon = lon;
                onPointerDownLat = lat;

                console.log('onDocumentTouchStart', event.touches[0].clientX, event.touches[0].clientY, lon, lat)
            }
        
            function onDocumentTouchMove( event ) {
                // console.log('onDocumentMouseMove', isUserInteracting);
                if ( isUserInteracting === true ) {

                    if (props.rotation !== 'vertical') {
                       
                        let lonCalc = ( onPointerDownPointerX - event.touches[0].clientX ) * 0.1 + onPointerDownLon;
                        // console.log('lon', lon, props.endPos, event.clientX, onPointerDownPointerX)
                        if (props.endPos) {
                            if (lonCalc > props.startPos && lonCalc < props.endPos) {
                                lon = ( onPointerDownPointerX - event.touches[0].clientX ) * 0.1 + onPointerDownLon;
                                setPosX(lon);
                            }
                        } else {
                            lon = ( onPointerDownPointerX - event.touches[0].clientX ) * 0.1 + onPointerDownLon;
                                setPosX(lon);
                        } 
                        
                 

                    }
                    if (props.rotation !== 'horizontal') {
                        lat = ( event.touches[0].clientY - onPointerDownPointerY ) * 0.1 + onPointerDownLat;
                    }
                }
            }
        
            function onDocumentTouchEnd( event ) {
                isUserInteracting = false;
            }
        
        
            function onDocumentMouseWheel( event ) {
                var currFov = camera.fov;
                var change = event.deltaY * 0.05;
                currFov+=change;
                console.log('onDocumentMouseWheel', event.deltaY);
                if ((currFov < 85) && (currFov > 20)) {
                    camera.fov += change;
                    camera.updateProjectionMatrix();
                } 
            }

            function animate() {
                requestAnimID = requestAnimationFrame( animate );
                update();
            }
        
            function update() {
                lat = Math.max( - 85, Math.min( 85, lat ) );
                phi = THREE.Math.degToRad( 90 - lat );
                theta = THREE.Math.degToRad( lon );
                camera.target.x = 500 * Math.sin( phi ) * Math.cos( theta );
                camera.target.y = 500 * Math.cos( phi );
                camera.target.z = 500 * Math.sin( phi ) * Math.sin( theta );
                camera.lookAt( camera.target );
                renderer.render( scene, camera );
            }

            if (props.rotation !== 'vertical') {
                console.log('3js', onPointerDownPointerX, lon, onPointerDownLon)
                // lon = ( onPointerDownPointerX - 180 ) * 0.1 + onPointerDownLon;
                lon = ( 0 - 0 ) * 0.1 + onPointerDownLon;
              
            }
            // TEST: Turn back on
            // socket.on('cast', data => {
            //     if (data.indexOf("vrfile-mousemove") !== -1) {
            //         let pos = data.split('?pos=');
            //         // console.log('vrfile-mousemove pos', pos)
            //         if (pos) {
            //           let objs = pos[1].split(',');
            //         //   console.log('x,y',objs[0], objs[1])
            //           let x = Number(objs[0])
            //           let y = Number(objs[1])
            //           // let cursor = document.getElementById("remoteCursor");
            //           // gsap.to(cursor, {x: x, y: y, duration: 0});
            
            //           lon = x

            //           setPosX(lon)
                      
            //         }
            //       }

            // });
            


            // if (props.remoteMouse) {
            //     isUserInteracting = true;
            //     lon = Number(props.remoteMouse.lon)
            //     // update()
            //     isUserInteracting = false;
            //     // setPosY(props.remoteMouse.lat)
            //     console.log('setPosX', Number(props.remoteMouse.lon))
             
            // }
   

            setPosX(lon);   
    
            return () => {
                window.cancelAnimationFrame(requestAnimID);
                document.removeEventListener( 'mousedown', onDocumentMouseDown, false );
                document.removeEventListener( 'mousemove', onDocumentMouseMove, false );
                document.removeEventListener( 'mouseup', onDocumentMouseUp, false );
                document.removeEventListener( 'wheel', onDocumentMouseWheel, false );
                window.removeEventListener( 'resize', onWindowResize, false);
                document.removeEventListener( 'touchstart', onDocumentTouchStart, false );
                document.removeEventListener( 'touchmove', onDocumentTouchMove, false );
                document.removeEventListener( 'touchend', onDocumentTouchEnd, false);     
               
                
            };

        }
       
    }, [props.load, props.file, props.startPos, props.endPos, props.remoteMouse]);

    // useEffect(() => {
    //     // console.log('props.remoteMouse', props.remoteMouse)
        
    // }, [props.remoteMouse])



    useEffect(() => {
        if (props.vrZoom) {
            var currFov = cameraRef.current.fov;
            var change = props.vrZoom;
            console.log('change', change, currFov, (currFov + change))
            var vrInterval = setInterval(() => {
                if (((change > 0) && (cameraRef.current.fov  < (currFov+change)))||((change < 0) && (cameraRef.current.fov  > (currFov+change)))) {
                    cameraRef.current.fov+=(props.vrZoom/20); 
                    console.log('fov', cameraRef.current.fov, currFov+change);
                    if ((currFov < 85) && (currFov > 20)) {
                        cameraRef.current.updateProjectionMatrix();
                    } 
                } else {
                    clearInterval(vrInterval);
                    console.log('fov done');
                }
                
            }, 30);



            props.setVrZoom(null);
        }
    }, [props.vrZoom])


    return (
        <div>
            {(vrLoaded)?(null):(<div className={`${galleryStyles.vrLoadingContainer} fullscreen fcenter`}><div><LoaderWheel status={'VR Loading'} ></LoaderWheel></div></div>)}
            {(props.load) ? (<React.Fragment>
            
            {props.vrRef && <span className={`${galleryStyles.vrRefImg}`}>
                <span ref={vrCone} className={`${galleryStyles.vrCone}`} ><VrCone /></span>
                <img ref={vrRef} src={getContentPath(props.vrRef)} alt="vrRefImg" />
            </span>}
            
            <div id="vrContainer" ref={refContainer}></div></React.Fragment>) : (null) }
        </div>
        
    )   

}

export default ThreeVR;

